exports.onRouteUpdate = ({ location, prevLocation }) => {
  tql.init({ tags: ["PROVIDED", "TAGS", "HERE"] });

  const cta1 = document.getElementById("cta-get-quotes")
  cta1 && cta1.addEventListener("click", function (e) {
    e.preventDefault();
    tql.show()
  });
  
  if (location.pathname === '/') {
    const cta2 = document.getElementById("cta-get-three-quotes")
    cta2 && cta2.addEventListener("click", function (e) {
      e.preventDefault();
      tql.show();
    });
  }
}

/* eslint-disable no-mixed-operators */
const tql = {};

tql.init = function (config) {
  if (!tql.hasOwnProperty("iframe")) {
    let base = config && config.url || "https://totalquote.io/compare/";
    let tags = config && config.tags || [];
    let query = tags.map(function (tag) {
      return "tags=" + tag
    }).join("&");

    tql.iframe = document.createElement("iframe");
    tql.iframe.style.height = "100%";
    tql.iframe.style.width = "100%";
    tql.iframe.style.border = "0px none transparent";
    tql.iframe.style.margin = "0";
    tql.iframe.style.padding = "0";
    tql.iframe.src = encodeURI(base + "?" + query);

    tql.form = document.createElement("div");
    tql.form.style.display = "none";
    tql.form.style.visibility = "visible";
    tql.form.style.margin = "0";
    tql.form.style.padding = "0";
    tql.form.style.position = "fixed";
    tql.form.style.left = "0";
    tql.form.style.top = "0";
    tql.form.style.right = "0";
    tql.form.style.bottom = "0";
    tql.form.style.zIndex = "1000001";
    tql.form.style["-webkit-overflow-scrolling"] = "touch"; // ios scrolling
    tql.form.style.overflowX = "hidden";
    tql.form.style.overflowY = "scroll";

    tql.form.appendChild(tql.iframe);
    document.body.appendChild(tql.form);
  }
};

tql.show = function () {
  tql.form.style.display = "block";
};

tql.hide = function () {
  tql.form.style.display = "none";
};

window.addEventListener("message", function (event) {
  if (event.data.type === "close") {
    tql.hide()
  }
});
