// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-installer-profile-page-js": () => import("/opt/render/project/src/src/templates/installer-profile-page.js" /* webpackChunkName: "component---src-templates-installer-profile-page-js" */),
  "component---src-templates-careers-page-js": () => import("/opt/render/project/src/src/templates/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/render/project/src/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-solar-guide-page-js": () => import("/opt/render/project/src/src/templates/solar-guide-page.js" /* webpackChunkName: "component---src-templates-solar-guide-page-js" */),
  "component---src-templates-main-page-js": () => import("/opt/render/project/src/src/templates/main-page.js" /* webpackChunkName: "component---src-templates-main-page-js" */),
  "component---src-templates-bgimage-page-js": () => import("/opt/render/project/src/src/templates/bgimage-page.js" /* webpackChunkName: "component---src-templates-bgimage-page-js" */),
  "component---src-templates-simple-page-js": () => import("/opt/render/project/src/src/templates/simple-page.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-templates-review-search-page-js": () => import("/opt/render/project/src/src/templates/review-search-page.js" /* webpackChunkName: "component---src-templates-review-search-page-js" */),
  "component---src-templates-signup-page-js": () => import("/opt/render/project/src/src/templates/signup-page.js" /* webpackChunkName: "component---src-templates-signup-page-js" */),
  "component---src-templates-blog-post-page-js": () => import("/opt/render/project/src/src/templates/blog-post-page.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-blog-page-js": () => import("/opt/render/project/src/src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-solar-special-page-js": () => import("/opt/render/project/src/src/templates/solar-special-page.js" /* webpackChunkName: "component---src-templates-solar-special-page-js" */),
  "component---src-templates-location-page-js": () => import("/opt/render/project/src/src/templates/location-page.js" /* webpackChunkName: "component---src-templates-location-page-js" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-test-js": () => import("/opt/render/project/src/src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/render/project/src/.cache/data.json")

